/*-
 * *****
 * Concord
 * -----
 * Copyright (C) 2017 - 2018 Walmart Inc.
 * -----
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *      http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =====
 */

html {
  height: 100% !important;
  min-height: 100% !important;
}

body {
  min-height: 100% !important;
  margin-right: calc(-1 * (100vw - 100%));
}

#root {
  height: 100% !important;
  min-height: 100% !important;
  margin-top: 5px;
}

.maxHeight {
  height: 100% !important;
  min-height: 100% !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui.fullscreen.modal {
  left: auto !important;
}

.loading {
  opacity: 0.5;
}