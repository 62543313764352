/*-
 * *****
 * Concord
 * -----
 * Copyright (C) 2017 - 2019 Walmart Inc.
 * -----
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *      http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =====
 */

.LogSegment {
    color: rgb(22, 22, 22);
}

.LogSegment .Segment {
    text-align: left;
    background-color: white;
    position: sticky;
    top: 57px;
}

.LogSegment .LogSegment {
    margin-left: 30px;
}

.LogSegment .Segment:focus {
    background: white none;
}

.LogSegment .Segment:hover {
    background-color: rgb(230, 230, 230);
    border-radius: 3px;
}

.LogSegment .Loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #2a2929;
    animation: progress-active 3s ease infinite;
    animation-delay: 100ms;
}

.LogSegment .Segment .Caption {
    padding-left: 10px;
    font-family: monospace;
    font-weight: normal;
    color: rgba(43, 43, 43, 0.9);
}

.LogSegment .Segment .State {
    margin: 0 !important;
    padding-right: 20px;
}

.LogSegment .Segment .Status {
    margin: 0 !important;
}

.LogSegment .Segment .EmptyStatus {
    margin: 0;
    width: 1.18em;
    display: inline-block;
}

.LogSegment .Segment .AdditionalAction {
    margin-top: 0 !important;
    float: right;
    padding-right: 10px;
}

.LogSegment .Segment .AdditionalAction.Last {
    padding-right: 0;
    color: #4183c4;
}

.LogSegment .Segment .AdditionalAction.Anchor {
    padding-right: 0;
    padding-left: 10px;
    color: #4183c4;
}

.LogSegment .Segment .AdditionalAction .on {
    color: #4183c4;
}

.LogSegment .Segment .AdditionalAction .off {
    color: #767676;
}

.LogSegment .Segment .AdditionalAction .off:hover {
    color: #000000;
}

.LogSegment .Segment .AdditionalAction i:hover{
    color: rgb(43, 43, 43) !important;
}

.LogSegment .ContentContainer {
    padding: 0 0 0 40px;
}

.LogSegment .InnerContentContainer {
    overflow: auto;
    min-height: 12px;
}

.LogSegment .Loading {
    margin: 0;
    padding: 10px 0 10px 10px;
    color: rgb(241, 241, 241);
    background: rgb(43, 43, 43);
}

.LogSegment .Content {
    color: rgb(43, 43, 43);
    font-family: monospace;
    line-height: 18px;
    background: rgb(255, 255, 255);
    margin: 0;
    display: inline-block;
    min-width: 100%;
}

.LogSegment .Content pre {
    margin: 0;
    white-space: pre-wrap;
}

.LogSegment .Content a {
    color: #00B5F0;
    text-decoration: underline;
}

.LogSegment .Counter {
    font-weight: normal;
    color: #888888;
    margin-left: 10px;
}

.LogSegment .RunningFor {
    font-weight: normal;
    color: #888888;
    margin-left: 10px;
}
