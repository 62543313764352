/*-
 * *****
 * Concord
 * -----
 * Copyright (C) 2017 - 2019 Walmart Inc.
 * -----
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *      http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =====
 */

.playbookStatsLabel {
    color: rgba(0, 0, 0, 0.5) !important;
}

.playbookStatsBlockToggled {
    transform: translateY(-4px) !important;
}

.playProgress {
    margin: 0 !important;
}

.playProgress.nonactive .bar {
    opacity: 0;
}

.taskProgressCell {
    border-top: 0 !important;
}

.taskLegendColor {
    display: inline-block !important;
    vertical-align: middle;
}

.taskLegendContent {
    padding-left: .75rem;
    font-weight: normal !important;
}

.taskProgress {
    position: relative;
    display: block;
    max-width: 100%;
    border: none;
    background: rgba(0,0,0,.1);
    padding: 0;
}

.taskProgress .bar {
    display: block;
    line-height: 1;
    position: relative;
    min-width: 2em;
    height: 1.75em;
    background: #888;
}

.taskProgress .bar .progress {
    white-space: nowrap;
    position: absolute;
    width: auto;
    font-size: .92857143em;
    top: 50%;
    right: .5em;
    left: auto;
    bottom: auto;
    color: rgba(255,255,255,.7);
    text-shadow: none;
    margin-top: -.5em;
    font-weight: 700;
    text-align: left;
}

.taskCounterActive {
    opacity: 1;
}

.taskCounterNonactive {
    opacity: 0.3;
}

.selectedPlay {
    background-color: #F2F2F2;
}